import {NgModule} from "@angular/core";
import {DisableControlDirective} from "./disabled-control.directive";



@NgModule({
    imports: [


    ],
    declarations: [DisableControlDirective],
    exports: [DisableControlDirective]
})

export class DisabledControlModule {}