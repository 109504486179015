/**
 * Created by vladimir.cimprich on 15.08.2017.
 */
import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";

/**
 * Implements properties and methods, that can be included (via dependency injection)
 * into Angular 2 components which need load data about the products.
 */
@Injectable()
export class ProductsAdministrationService {

    constructor(private http: HttpClient) {
    }

    /**
     * @description Loads the paged list of the products.
     * @param selectedCategoryId The primary key of the selected category.
     * @param pageIndex The index of a page.
     * @param pageSize Data count to display per page.
     * @returns {Subscription} Returns the Observable object having the Http response type.
     */
    loadProductsList(selectedCategoryId: number, pageIndex: number, pageSize: number) : any {
        if (typeof selectedCategoryId === 'undefined') {
            throw new RangeError('Parameter selectedCategoryId is undefined.');
        }

        if (typeof pageIndex === 'undefined') {
            throw new RangeError('Parameter pageIndex is undefined.');
        }

        if (typeof pageSize === 'undefined') {
            throw new RangeError('Parameter pageSize is undefined.');
        }
// todo: strong type
        return this.http.get(`api/products/getProducts/${selectedCategoryId}/${pageIndex}/${pageSize}`);
    }

}