import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from "@angular/core";
import {ICulture, Translatable} from "../../interfaces/general";
import {DataService} from "../../services/data.service";
import {SettingsService} from "../../services/settings.service";
import {TreeNode} from "primeng/primeng";
import {internalMode} from "./common";
import {Subject} from "rxjs";
import {takeUntil} from "rxjs/operators";
import {HttpClient} from "@angular/common/http";
import {splitCategoriesByTree} from "../../helpers/category.helper";
import {ICategory} from "../categories/common";

@Component({
    selector: 'cmp-categories-selector',
    templateUrl: '../../tpl/categories-selector.html'
})
export class CategoriesSelectorComponent extends Translatable implements OnChanges {

    @Input() selectionMode: internalMode = 'single';
    @Input() selectedIds: number[];
    @Input() styleClass: string;
    @Output() selectedChanged: EventEmitter<TreeNode[]> = new EventEmitter<TreeNode[]>();

    cultureId: number;
    cultures: ICulture[];

    selectedCategories: TreeNode[];
    categories: TreeNode[];
    ngUnsubscribe: Subject<any> = new Subject<any>();

    constructor(dataSvc: DataService, seSvc: SettingsService, private http: HttpClient) {
        super(dataSvc, seSvc);

        this.cultureId = this.seSvc.settings.defaultCultureId;
        this.cultures = this.seSvc.settings.cultures;
    }

    getRootCategories(): void {
        this.http.get<ICategory[]>('api/categories/getCategoryTree/')
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(res => {
                this.categories = splitCategoriesByTree(res, this.sen);
            })
    }

    getAllCategories(): void {
        this.http.get<ICategory[]>('api/categories/getCategoryTree')
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(res => {                this.categories = res;

            })
    }

    getSubCategories(evt: any): void {
        this.http.get(`api/categories/getCategories/${evt.node.data}`)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(res => {
                evt.node.children = res;
            })
    }

    ngOnInit(): void {
        if (this.selectionMode == 'checkbox' || this.selectionMode == 'multiple') {
            this.getAllCategories();
        }
        else {
            this.getRootCategories();
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.selectedIds && !changes.selectedIds.firstChange) {
            this.setSelected(changes.selectedIds.currentValue || []);
        }
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    nodeSelectionChanged(evt: any): void {
        let objToBeEmitted: TreeNode[];
        if (this.selectionMode == 'single') {
            objToBeEmitted = evt;
        }
        else {
            objToBeEmitted = this.selectedCategories;
        }
        this.selectedChanged.emit(objToBeEmitted);
    }

    loadNode(evt: any): void {
        if (this.selectionMode == 'single') {
            if (evt.node.children == undefined && evt.node.leaf == false) {
                this.getSubCategories(evt);
            }
        }
    }

    setSelected(ids: number[]): void {
        this.selectedCategories = [];

        for (let item in this.categories) {
            this.checkIsSelected(this.categories[item], ids);
            this.setSelectedIn(this.categories[item], ids);
        }
    }

    private setSelectedIn(item: TreeNode, ids: number[]): void {
        for (let child in item.children) {
            this.checkIsSelected(item.children[child], ids);
        }
    }

    private checkIsSelected(item: TreeNode, ids: number[]): void {
        for (let i in ids) {
            if (item.data == ids[i]) {
                this.selectedCategories.push(item);
            }
        }
    }
}