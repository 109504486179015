
import {TreeModule} from "primeng/primeng";
import {CommonModule} from "@angular/common";
import {NgModule} from "@angular/core";
import {CategoriesSelectorComponent} from "./categories-selector.component";

@NgModule({
    imports: [
        CommonModule,
        TreeModule
    ],
    declarations: [CategoriesSelectorComponent],
    exports: [CategoriesSelectorComponent]
})

export class CategoriesSelectorModule {}