﻿import {ICategory} from "../modules/categories/common";

export function splitCategoriesByTree(res: ICategory[], sen: any): ICategory[] {
    let categories: ICategory[] = [];

    for (let r of res) {
        if (r.treeId > 0 || r.treeId === 0) {
            let root = categories.find((q: ICategory) => q.data == -r.treeId - 1);
            if (!root) {
                root = <ICategory> {
                    data: -r.treeId - 1,
                    children: [],
                    leaf: false,
                    expanded: true,
                    selectable: false,
                    label: sen['admin-category-tree-' + r.treeId],
                    treeId: r.treeId
                };
                categories.push(root);
            }
            root.children.push(r);
        } else {
            categories.push(r);
        }
    }

    return categories;
}